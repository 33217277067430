import cls from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './styles.module.scss';
import { IReasonItem } from '../interface';
import { useTranslation } from 'next-i18next';
import { checked } from '../constants';

export type IStep3Props = {
  getCheckedReason: () => void;
};
const CANCEL_REASON = [
  '时间安排变化',
  '孩子没兴趣',
  '课程内容不匹配',
  '老师不适合孩子',
  '已经报名其他课程      ',
  '其他',
];
const CANCEL_REASON_EN = [
  'Changed schedule',
  "My child isn't very interested",
  'Course content may not be suitable',
  'Teacher may not be a good fit',
  'Already signed up for another course',
  'Other',
];
const Step3 = (_: any, ref: any) => {
  const { i18n } = useTranslation(['trailCourse']);
  const [reasonList, setReasonList] = useState<IReasonItem[]>([]);

  useEffect(() => {
    const reasons = i18n.language == 'zh' ? CANCEL_REASON : CANCEL_REASON_EN;
    setReasonList(
      reasons?.map((reason) => {
        return {
          reason,
          isChecked: false,
        };
      }),
    );
  }, []);

  const handleCheck = (currentIndex: number) => {
    const nextList = reasonList?.map((item, index) => {
      return {
        reason: item.reason,
        isChecked: currentIndex === index ? !item?.isChecked : false,
      };
    });
    setReasonList([...nextList]);
  };

  useImperativeHandle(ref, () => ({
    getCheckedReason: () => {
      return reasonList?.reduce((prev: string[], cur) => {
        if (cur.isChecked) {
          prev.push(cur?.reason);
        }
        return prev;
      }, []);
    },
  }));

  const ReasonItem = ({ reason, isChecked, index }: IReasonItem) => {
    return (
      <div
        className={cls(styles.reasonItem, isChecked ? styles.active : null)}
        onClick={() => handleCheck(index as number)}
      >
        {reason}
        {isChecked && (
          <div className={styles.activeIcon}>
            <img className={styles.checked} src={checked.src} alt="checked" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={cls(styles.step3MainContent, 'mobile:mt-[24px] pc:mt-[32px]')}>
      {reasonList.map((item, index) => {
        const { reason, isChecked } = item;
        return <ReasonItem key={reason} reason={reason} isChecked={isChecked} index={index} />;
      })}
    </div>
  );
};

export default forwardRef<any, any>(Step3);
