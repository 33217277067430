import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import { ICourseInfo } from '../interface';
import styles from './styles.module.scss';
interface IProps {
  data?: Partial<ICourseInfo>;
  language?: string;
  routeToGs2Booking?: () => void;
}

const Step4 = ({ data, routeToGs2Booking }: IProps) => {
  const { teacherName, teacherAvator } = data || {};
  const { t } = useTranslation(['trailCourse']);
  return (
    <div className={cls(styles.step4MainContent, 'mobile:mt-[24px] pc:mt-[32px]')}>
      <div className={styles.left}>
        <div className={styles.avatorBox}>
          <img className={styles.avator} src={teacherAvator} alt="avator" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.name}>{teacherName}</div>
        <div className={cls(styles.dialog, 'mobile:mt-[8px] mobile:p-[16px] pc:mt-[16px] pc:p-[24px]')}>
          <span>
            {t('取消课程-步骤四描述1')}
            <a className={styles.link} onClick={routeToGs2Booking}>
              {t('取消课程-步骤四-再次预约')}
            </a>
            {t('取消课程-步骤四描述2')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Step4;
