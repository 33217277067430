/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import { Input, Toast } from '@/components';
import { checkForgetPwd, sendBindCode, sendVerifyCodeV2, sendVerifyCodeV3 } from '@/api/login';
import { REGEXP_EMAIL } from '@/utils/constants';

import styles from './style.module.scss';
import VerifySlideFixed, { CaptchaSuccess } from '@/pageComponents/verifition/verifySlideFixed';

export interface CodeInputProps {
  placeholder: string;
  className?: string;
  onChange: (value: string) => void;
  target: string | null;
  maxLength?: number;
  onPressEnter?: () => void;
  type: 'EMAIL' | 'MOBILE_PHONE';
  way: 'LOGIN' | 'REGISTER';
  mode?: 'Normal' | 'Bind';
  onShowMsg?: () => void;
  codeClassName?: string;
  auto?: boolean;
  value?: string;
  apiUrl?: string;
  special?: boolean; // 特殊处理
  onNeedRegister?: (value: boolean) => void;
}

const DEFAULT_COUNTDOWN_TIME: number = 60;

const CodeInput: React.FC<CodeInputProps> = (props) => {
  const {
    placeholder,
    className,
    onChange,
    target,
    maxLength,
    type,
    codeClassName,
    mode = 'Normal',
    way,
    value,
    onShowMsg,
    onPressEnter,
    apiUrl,
    special,
    onNeedRegister,
  } = props;
  const [sendFlag, setSendFlag] = useState(false);
  const { t } = useTranslation('home');
  const DEFAULT_CODE_TEXT = t('获取验证码');
  const [countDown, setCountDown] = useState<number>(-1);
  const [codeText, setCodeText] = useState(DEFAULT_CODE_TEXT); // 倒计时时长
  const [isSlideShow, setIsSlideShow] = React.useState<boolean>(false);

  const verifyTarget = useCallback((): boolean => {
    let result = false;
    if (type === 'EMAIL') {
      result = !REGEXP_EMAIL.test(target as string);
      result && Toast.warning(t('邮箱格式错误'));
      return result;
    }
    return result;
  }, [t, target, type]);

  // 发送验证码
  const sendCode = useCallback(
    async (code?: string) => {
      if (sendFlag) {
        return;
      }
      setSendFlag(true);
      if (codeText !== DEFAULT_CODE_TEXT || verifyTarget()) {
        setSendFlag(false);
        return;
      }

      setCountDown(DEFAULT_COUNTDOWN_TIME);

      if (special) {
        const { errorCode = '' } = await checkForgetPwd(type === 'EMAIL' ? 'EMAIL' : 'MOBILE', target);

        if (errorCode === 1220) {
          setCountDown(-1);
          onShowMsg?.();
          return;
        }
        if (errorCode === 1221) {
          onNeedRegister?.(true);
        }
      }

      if (apiUrl) {
        sendVerifyCodeV3(apiUrl, {
          target,
          verificationType: type,
          authenticationWay: way,
          captchaVerification: code,
        })
          .then((res) => {
            const { success, errorCode } = res;
            if (success === false && errorCode === 1201) {
              setCountDown(-1);
              onShowMsg?.();
            }
          })
          .catch(() => {
            setCountDown(-1);
          });
        return;
      }

      if (mode === 'Normal') {
        sendVerifyCodeV2({ target, verificationType: type, authenticationWay: way, captchaVerification: code })
          .then((res) => {
            const { errorCode } = res || {};
            if (errorCode === 407 || errorCode === 406) {
              setCountDown(-1);
              onShowMsg?.();
            }
          })
          .catch(() => {
            setCountDown(-1);
          });
      } else {
        sendBindCode({ target, verificationType: type, captchaVerification: code })
          .then((res) => {
            const { errorCode } = res || {};
            if (errorCode === 407 || errorCode === 406) {
              setCountDown(-1);
              onShowMsg?.();
            }
          })
          .catch(() => {
            setCountDown(-1);
          });
      }
    },
    [sendFlag, mode, codeText, apiUrl, DEFAULT_CODE_TEXT, verifyTarget, target, type, way, onShowMsg],
  );

  const handleSend = () => {
    target && codeText === DEFAULT_CODE_TEXT && setIsSlideShow(true);
  };

  useEffect(() => {
    let timer = -1;
    if (countDown > 0) {
      timer = window.setTimeout(() => {
        const time = countDown - 1;
        setCodeText(`${time} s`);
        setCountDown(time);
      }, 1e3);
    } else {
      setCodeText(DEFAULT_CODE_TEXT);
      setSendFlag(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countDown, DEFAULT_CODE_TEXT]);

  const codeNode = (
    <div role="link" className={codeClassName} onClick={handleSend}>
      {codeText}
    </div>
  );

  const closeVerify = () => {
    setIsSlideShow(false);
  };

  const verifySuccess = async ({ captchaVerification }: CaptchaSuccess) => {
    setIsSlideShow(false);
    sendCode(captchaVerification);
  };

  return (
    <>
      <VerifySlideFixed isSlideShow={isSlideShow} onClose={closeVerify} success={verifySuccess} />
      <Input
        onChange={(e) => onChange(e.currentTarget.value)}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        maxLength={maxLength}
        suffixCls={cls(styles.code, codeClassName)}
        suffix={codeNode}
        className={className}
        value={value}
      />
    </>
  );
};

export default CodeInput;
